<template>
  <div>
    <div class="home-layer page4">
      <header-view/>
      <div class="introduce-bg"></div>
      <div class="introduce-content" v-show="isCurrentPage">
        <div class="left">
          <h2 v-show="notEn" class="fadeInDownBig animated delay1">{{ $t('lang.home.introduce.title') }}</h2>
          <p :class="{'lang-en':!notEn}" class="fadeInDownBig animated delay2">COMPANY PROFILE</p>

        </div>
        <div class="right">
          <p v-show="notEn" class="txt fadeInUp animated delay3" style="margin-top: 0">
            {{$t('lang.home.dcloudpage')}}
          </p>
          <p class="txt base-size fadeInUp animated delay4">
            ORENJI always adheres to the needs of users as the starting point, combined with leading data acceleration
            technology, to create products and solutions that truly meet the needs of users. In terms of static resource
            acceleration, dcloud integrated CDN has successfully provided integrated CDN services for nearly 500000
            websites through cooperation with domestic well-known front-end open-source station bootcdn and well-known
            UI component library iView.
          </p>
        </div>
      </div>
      <footer-view/>
    </div>
  </div>
</template>

<script>
import headerView from "@/components/layout/header";
import footerView from "@/components/layout/footer";

export default {
  name: "introducePage",
  props: {
    swiper: Object
  },
  computed: {
    notEn(){
      return this.$i18n.locale !=='en'
    },
    isCurrentPage() {
      let idx = 0;
      if (this.swiper && this.swiper.hasOwnProperty('realIndex')) {
        idx = this.swiper.realIndex
      }
      return idx === 5
    }
  },
  components: {
    headerView,
    footerView
  }
}
</script>

<style lang="less" scoped>
.introduce-bg {
  height: 80%;
  width: 100%;
  position: absolute;
  background-image: url('../../public/images/introducepage.jpg');
  background-size: cover;
}

.introduce-content {
  width: 1200px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  left: 50%;
  top: 39%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  .left,
  .right {
    text-align: left;
  }

  .left {
    > h2 {
      font-size: 44px;
      font-weight: 500;
    }

    > p {
      font-size: 18px;
      &.lang-en{
        font-size: 36px;
      }
    }
  }
}

.durationDelay(@duration, @delay) {
  animation-duration: @duration;
  -webkit-animation-duration: @duration;
  animation-delay: @delay;
  -webkit-animation-delay: @delay;
}

.swiper-slide-active {
  .delay1 {
    .durationDelay(1s, 0.5s)
  }

  .delay2 {
    .durationDelay(1s, 1s)
  }

  .delay3 {
    .durationDelay(1s, 1s)
  }

  .delay4 {
    .durationDelay(1s, 2s)
  }
}

.txt {
  width: 640px;
}
</style>

