<template>
  <div>
    <div class="home-layer page3">
      <header-view/>
      <div class="mupao-bg"></div>
      <div class="mupao-content"
       v-show="isCurrentPage">
        <h2 v-show="notEn" class="fadeInUp animated delay1">{{$t('lang.home.mupaoPage.title')}}</h2>
        <p  class="e_des fadeInUp animated delay2">
          MUPAO
          <br>
          ADVERTISING ALLIANCE
        </p>
        <p class="hr fadeInUp animated delay3"></p>
        <p v-show="notEn" class="txt fadeInUp animated delay4">
          {{$t('lang.home.mupaoPage.des')}}
        </p>
        <p class="txt base-size fadeInUp animated delay5">
          Mupao advertising alliance is an Internet marketing company focusing on Internet advertising and network
          marketing. It has rich experience in network advertising and network planning. It focuses on the research and
          development of online advertising and provides various forms of online advertising service for customers.
        </p>
        <a class="learnmore fadeInDown animated delay6">{{$t('lang.home.learnmore')}}</a>
      </div>
    </div>
    <img
        v-show="isCurrentPage"
        @click="jump(3)"
        class="arrowdown fadeInDown animated delay4"
        src="../../public/images/arrow-down.png">
  </div>
</template>

<script>
import headerView from "@/components/layout/header";

export default {
  name: "mupaoPage",
  props: {
    swiper: Object
  },
  computed: {
    notEn(){
      return this.$i18n.locale !=='en'
    },
    isCurrentPage() {
      let idx = 0;
      if (this.swiper && this.swiper.hasOwnProperty('realIndex')) {
        idx = this.swiper.realIndex
      }
      return idx === 4
    }
  },
  methods: {
    jump(idx) {
      this.swiper.slideTo(idx, 300, false)
    }
  },
  components: {
    headerView
  }
}
</script>

<style lang="less" scoped>

.mupao-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: url('../../public/images/mupaopage.jpg');
  background-size: cover;
}

.mupao-content {
  position: absolute;
  width: 640px;
  left: 18%;
  top: 50%;
  font-weight: normal;
  text-align: left;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  > h2 {
    font-size: 40px;
    font-weight: 500;
  }

  .e_des {
    margin-top: 20px;
    line-height: 39px;
    font-size: 24px;
  }
}

.durationDelay(@duration, @delay) {
  animation-duration: @duration;
  -webkit-animation-duration: @duration;
  animation-delay: @delay;
  -webkit-animation-delay: @delay;
}


.swiper-slide-active {
  .delay1 {
    .durationDelay(1s, 0.5s)
  }

  .delay2 {
    .durationDelay(1s, 1s)
  }

  .delay3 {
    .durationDelay(1s, 1.5s)
  }

  .delay4 {
    .durationDelay(1s, 2s)
  }

  .delay5 {
    .durationDelay(1s, 2.5s)
  }

  .delay6 {
    visibility: visible;
    .durationDelay(2s, 3s)
  }
}
.txt {
  width: 640px;
}
</style>
