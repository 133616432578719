import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/orenji',
        name: 'ORENJI',
        component: () => import(/* webpackChunkName: "orenji" */ '../views/orenji/index.vue')
    },
    {
        path: '/oj/video',
        name: 'video',
        component: () => import(/* webpackChunkName: "video" */ '../views/orenji/video.vue')
    },
    {
        path: '/oj/ecs',
        name: 'ECS',
        component: () => import(/* webpackChunkName: "esc" */ '../views/orenji/ecs.vue')
    },
    {
        path: '/pay',
        name: 'pay',
        component: () => import(/* webpackChunkName: "pay" */ '../views/pay/index.vue')
    },
    {
        path: '/mupao',
        name: 'Mupao',
        component: () => import(/* webpackChunkName: "mupao" */ '../views/mupao.vue')
    },
    // {
    //     path: '/join',
    //     name: 'Join',
    //     component: () => import(/* webpackChunkName: "mupao" */ '../views/join.vue')
    // },
    {
        path: '*',
        name: '404',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash
            }
        }
    }
})

export default router
