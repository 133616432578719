<template>
  <div class="service">
    <!-- <div class="service-bg"> </div> -->
    <header-view />
    <div class="service-conten">
      <div class="img-item img1">
        <div class="income" v-show="isCurrentPage">
          <h4 class="fadeInDownBig animated delay1">
            ￥ <span class="size">5.3</span> {{ $t("lang.home.income.yi") }}
          </h4>
          <div class="fadeInUp animated delay2">
            <p class="new" v-show="lang">
              {{ $t("lang.home.service.fristTitle") }}
            </p>
            <p class="eng">Annual Revenue</p>
          </div>
        </div>
      </div>
      <div class="img-item img2">
        <div class="income" v-show="isCurrentPage">
          <h4 class="fadeInDownBig animated delay3">
            <span class="size">20</span> {{ $t("lang.home.income.people") }}
          </h4>
          <div class="fadeInUp animated delay4">
            <p class="new" v-show="lang">
              {{ $t("lang.home.service.twoTitle") }}
            </p>
            <p class="eng">Employees</p>
          </div>
        </div>
      </div>
      <div class="img-item img3">
        <div class="income" v-show="isCurrentPage">
          <h4 class="fadeInUp animated delay5">
            <span class="size">11</span> {{ $t("lang.home.income.ge") }}
          </h4>
          <div class="fadeInUp animated delay6">
            <p class="new" v-show="lang">{{ $t("lang.home.service.three") }}</p>
            <p class="eng">Worldwide Offices</p>
          </div>
        </div>
      </div>
      <div class="img-item img4">
        <div class="income" v-show="isCurrentPage">
          <h4 class="fadeInUp animated delay7">
            <span class="size">3000</span> +
          </h4>
          <div class="fadeInUp animated delay8">
            <p class="new" v-show="lang">{{ $t("lang.home.service.four") }}</p>
            <p class="eng">ClientS</p>
          </div>
        </div>
      </div>
      <div class="img-item img5">
        <div class="income" v-show="isCurrentPage">
          <h4 class="fadeInUp animated delay9">
            <span class="size">95</span> %
          </h4>
          <div class="fadeInUp animated delay10">
            <p class="new" v-show="lang">{{ $t("lang.home.service.five") }}</p>
            <p class="eng">Digital Revenue Business (%)</p>
          </div>
        </div>
      </div>
      <div class="img-item img6">
        <div class="income" v-show="isCurrentPage">
          <h4 class="fadeInUp animated delay11">
            <span class="size">28</span> {{ $t("lang.home.income.age") }}
          </h4>
          <div class="fadeInUp animated delay12">
            <p class="new" v-show="lang">{{ $t("lang.home.service.six") }}</p>
            <p class="eng">The Average Age of Employeesin Mainland China</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerView from "@/components/layout/header";
export default {
  name: "servicePage",
  props: {
    swiper: Object,
  },
  computed: {
    lang() {
      return this.$i18n.locale !== "en";
    },
    isCurrentPage() {
      let idx = 0;
      if (this.swiper && this.swiper.hasOwnProperty("realIndex")) {
        idx = this.swiper.realIndex;
      }
      return idx === 1;
    },
  },
  components: {
    headerView,
  },
};
</script>
<style lang="less" scoped>
.service {
  width: 100%;
  height: 100%;
}
.service-conten {
  width: 100%;
  height: 100%;
  .img-item {
    position: relative;
    float: left;
    width: 33.3%;
    height: 50%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    &.img1 {
      background-image: url("../../public/images/service-1.jpg");
    }
    &.img2 {
      background-image: url("../../public/images/service-2.jpg");
    }
    &.img3 {
      background-image: url("../../public/images/service-3.jpg");
    }
    &.img4 {
      background-image: url("../../public/images/service-4.jpg");
    }
    &.img5 {
      background-image: url("../../public/images/service-5.jpg");
    }
    &.img6 {
      background-image: url("../../public/images/service-6.jpg");
    }
    .income {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .size {
        font-size: 80px;
      }
      .new {
        font-size: 21px;
        font-weight: normal;
      }
      .eng {
        font-size: 12px;
        color: #fff;
      }
    }
  }
}
h4 {
  font-weight: 400;
  font-size: 24px;
}

.durationDelay(@duration, @delay) {
  animation-duration: @duration;
  -webkit-animation-duration: @duration;
  animation-delay: @delay;
  -webkit-animation-delay: @delay;
}

.swiper-slide-active {
  .delay1 {
    .durationDelay(1s, 0.5s);
  }

  .delay2 {
    .durationDelay(1s, 1.5s);
  }

  .delay3 {
    .durationDelay(1s, 2s);
  }

  .delay4 {
    .durationDelay(1s, 2.5s);
  }
  .delay5 {
    .durationDelay(1s, 3s);
  }

  .delay6 {
    .durationDelay(1s, 3.5s);
  }
  .delay7 {
    .durationDelay(1s, 4s);
  }

  .delay8 {
    .durationDelay(1s, 4.5s);
  }
  .delay9 {
    .durationDelay(1s, 5s);
  }
  .delay10 {
    .durationDelay(1s, 5.5s);
  }
  .delay11 {
    .durationDelay(1s, 6s);
  }
  .delay12 {
    .durationDelay(2s, 6.5s);
  }
}
</style>