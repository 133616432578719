export const lang = {
    header: {
        about: 'About BP',
        business: 'Business',
        orenji: 'ORENJI',
        video: "云点播",
        ecs: "Ecs",
        mupao: 'MUPAO',
        join: 'Join us'
    },
    footer: {
        company: 'Corporation',
        email: 'Report Mailbox',
        about: 'About us',
        link: 'Links',
        mupao: 'Mupao'

    },
    home: {
        mupaoPage: {
            title: "MUPAO ADVERTISING ALLIANCE"
        },
        learnmore: 'learnmore',
        income:{
            yi: 'a hundred million ',
            people:'people',
            ge: 'individuals',
            age: 'year'

        }
    },
    dcloud: {
        jump: 'GO ORENJI>>',
        companyTilie: 'COMPANY PROFILE',
        brand: {
            title: 'SUBSIDIARIES'
        },
        clients: {
            title: 'CLIENTS'
        },
        jp:{
            title: 'ADDRESS',
            add: 'Room 503, 5 / F, No. 32, 4-d-mu-2-fan, mayor of Osaka, Osaka Prefecture'
        },
        companyStory: {
            title: 'MILESTONE',
            size1: 'Blue Pointer Corporation was founded',
            size2: 'Open three branch offices',
            size3: 'Open five branches'
        }
    },
    mupao: {
        jump: 'GO MUPAO>>'
    }
}
