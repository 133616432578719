export const lang = {
    header: {
        about: '企業情報',
        business: 'ソリューション',
        orenji: 'ORENJI',
        video: "クラウドオンデマンド",
        ecs: "専用ホスト",
        mupao: '沐泡広告連盟',
        join: 'BPを選ぶ理由'
    },
    footer: {
        company: '株式会社',
        email: 'Email',
        about: '企業情報',
        link: 'リソース',
        mupao: '沐泡広告連盟'

    },
    home: {
        homepage: 'エンタープライズレベルの開発者にフルリンクのクラウドコンピューティングソリューションを提供するための専用サービスプラットフォーム',
        dcloudpage: 'ORENJIは、常にユーザーのニーズを出発点として、最先端のデータアクセラレーション技術と組み合わせて、ユーザーのニーズを真に満たす製品とソリューションを作成します。 静的リソースアクセラレーションに関して、ORENJI統合CDNは、有名な国内フロントエンドオープンソースWebサイトBootCDNおよび有名なUIコンポーネントライブラリiViewとの協力を通じて、約50万のWebサイトに統合CDNサービスを提供することに成功しました。',
        learnmore: 'もっと詳しく知る',
        internationalpage: {
            frist: '北米、西欧など、世界トップ500ブランドのブランドです ',
            two: 'そして「海に出て」の中国ブランドは国際的なコミュニケーションサービスを提供する。'
        },
        mupaoPage: {
            title: '沐泡広告連盟',
            des: 'Mupao Advertising Allianceは、インターネット広告とインターネットマーケティングに焦点を当てたインターネットマーケティング会社であり、インターネット広告とインターネット計画に非常に豊富な経験を持っています。インターネット広告の研究と開発に焦点を当て、さまざまな形式のオンライン広告を顧客に提供しています。'
        },
        introduce: {
            title: '企業情報'
        },
        internationaltitle: '国際業務',
        service:{
            fristTitle: '年間総収入',
            twoTitle: '会社員',
            three: 'グローバルオフィス',
            four: '客に奉仕する',
            five: 'デジタル化事業の売上比率',
            six: '国内労働者の平均年齢'
        },
        income:{
            yi: '億',
            people:'人',
            ge: '個',
            age: '歳'

        }
    },
    dcloud: {
        jp:{
            title: '会社の住所',
            add: '大阪府東大阪市長田東4丁目2番32号5階503号室'
        },
        txt: 'エンタープライズレベルの開発者にクラウドコンピューティングソリューションを提供するサービスプラットフォームに注力しています',
        jump: 'ORENJIに行きます>>',
        company: 'blue pointerは、ユーザーのニーズを起点に、先進的なデータ加速技術を組み合わせることで、ユーザーのニーズに本当に合った製品とソリューションを構築しています。orenji fusion cdnは静的リソースのアクセラレーションにおいて、国内で有名なオープンソースのフロントエンドのbootcdn、有名なuiコンポーネントのライブラリiviewと提携し、50万近くのウェブサイトにfusion cdnサービスを提供することに成功しました。',
        companyTilie: '会社紹介',
        brand: {
            title: 'サブブランド'
        },
        clients: {
            title: '顧客に奉仕する',
            size: '国内外の3000社以上のブランド顧客にサービスを提供し続けており、フォーチュン500企業100社を超える.'
        },
        companyStory: {
            title: '会社のマイルストーン',
            size1: 'ブルーポインター株式会社設立',
            size2: '3つの支社を開設する',
            size3: '5つの支社を開設する'
        }
    },
    mupao: {
        txt: '沐浴広告連盟はネット広告とネットマーケティングの分野に専念するネットマーケティング会社です。',
        jump: 'MUPAOに行きます>>'
    }
}
