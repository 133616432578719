<template>
  <div>
    <video
        class="banner-video"
        autoplay
        loop
        muted
        poster="../../public/images/homepage.jpg">
      <source src="../../public/video/homepage.mp4" type="video/mp4">
    </video>
    <audio controls autoplay style="opacity: 0">
      <source src="../../public/video/homepage.mp3" type="audio/mpeg">
    </audio>
    <audio src="../../public/video/homepage.mp3" autoplay/>
    <div class="home-layer page1">
      <header-view/>
      <div class="homepage-content" v-show="isCurrentPage">
        <p class="title fadeIn animated delay1">
          <img src="../../public/images/logo.png" alt="logo">
        </p>
        <p v-show="lang!=='en'" class="p1 fadeInUp animated delay2">{{$t('lang.home.homepage')}}</p>
        <p :class="{'lang-en': lang==='en'}" class="p2 fadeInUp animated delay3">
          A service platform dedicated to providing full-link cloud computing
          <br>
          solutions for enterprise-level developers
        </p>
      </div>
    </div>
    <img @click="jump(1)"
         v-show="isCurrentPage"
         class="arrowdown fadeInDown animated delay4"
         src="../../public/images/arrow-down.png">
  </div>
</template>

<script>
import headerView from "@/components/layout/header";

export default {
  name: "homepage",
  props: {
    swiper: Object
  },
  computed: {
    lang(){
      return this.$i18n.locale
    },
    isCurrentPage() {
      let idx = 0;
      if (this.swiper && this.swiper.hasOwnProperty('realIndex')) {
        idx = this.swiper.realIndex
      }
      return idx === 0
    }
  },
  methods: {
    jump(idx) {
      this.swiper.slideTo(idx, 300, false)
    }
  },
  components: {
    headerView
  }
}
</script>

<style lang="less" scoped>
.banner-video {
  z-index: 9;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: url(../../public/images/homepage.jpg) no-repeat;
  background-size: cover;
}

.homepage-content {
  width: 1200px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);

  .title {
    > img {
      width: 441px;
    }
  }

  .p1 {
    margin-top: 50px;
    font-size: 24px;
    letter-spacing: 1px;
  }

  .p2 {
    font-size: 18px;
    width: 750px;
    margin: 20px auto 0;
    &.lang-en{
      margin-top: 50px;
    }
  }

}

.durationDelay(@duration, @delay) {
  animation-duration: @duration;
  -webkit-animation-duration: @duration;
  animation-delay: @delay;
  -webkit-animation-delay: @delay;
}

.swiper-slide-active {
  .delay1 {
    visibility: visible;
    .durationDelay(1s, 1s)
  }

  .delay2 {
    visibility: visible;
    .durationDelay(1s, 2s)
  }

  .delay3 {
    visibility: visible;
    .durationDelay(1s, 2.5s)
  }

  .delay4 {
    visibility: visible;
    .durationDelay(2s, 3s)
  }
}
</style>
