export const lang = {
    header: {
        about: '关于BP',
        business: '核心业务',
        orenji: 'ORENJI',
        video: "云点播",
        ecs: "专用主机",
        mupao: '沐泡广告联盟',
        join: '加入我们'
    },
    footer: {
        company: '株式会社',
        email: '举报邮箱',
        about: '关于我们',
        link: '友情链接',
        mupao: '沐泡广告联盟'

    },
    home: {
        homepage: '致力于为企业级开发者提供全链路云计算解决方案的服务平台',
        dcloudpage: 'ORENJI始终坚持以用户需求为出发点，结合领先的数据加速技术，打造真正符合用户需求的产品和解决方案。ORENJI融合CDN在静态资源加速方面，通过与国内知名前端开源站BootCDN、知名UI组件库iView合作，成功为近50万家网站提供融合CDN服务。',
        learnmore: '了解更多',
        internationalpage: {
            frist: '为北美、西欧等众多全球500强品牌 ',
            two: '以及“出海”的中国品牌提供国际化传播服务。'
        },
        mupaoPage: {
            title: '沐泡广告联盟',
            des: '沐泡广告联盟专注于互联网广告及网络营销领域的互联网营销公司，有着非常丰富的网络广告和网络策划经验，专注于网络广告的研究与发展，为客户提供各种形式的网络广告投放服务。'
        },
        introduce: {
            title: '公司介绍'
        },
        internationaltitle: '国际业务',
        service:{
            fristTitle: '年度总营收',
            twoTitle: '公司员工',
            three: '全球办公室',
            four: '服务客户',
            five: '数字化业务收占比',
            six: '国内员工平均年龄'
        },
        income:{
            yi: '亿',
            people:'人',
            ge: '个',
            age: '岁'

        }
    },
    dcloud: {
        txt: '致力于为企业级开发者提供全链路云计算解决方案的服务平台',
        jump: '前往 ORENJI>>',
        company: 'BLUE POINTER始终坚持以用户需求为出发点，结合领先的数据加速技术，打造真正符合用户需求的产品和解决方案。Dcloud融合CDN在静态资源加速方面，通过与国内知名前端开源站BootCDN、知名UI组件库iView合作，成功为近50万家网站提供融合CDN服务。',
        companyTilie: '公司介绍',
        brand: {
            title: '子品牌'
        },
        clients: {
            title: '服务客户',
            size: '持续服务于3000多家国内外品牌客户，其中财富500强企业100余家.'
        },
        jp:{
            title: '公司地址',
            add: '大阪府东大阪市长田东4丁目2番32号5层 503室'
        },
        companyStory: {
            title: '公司里程碑',
            size1: 'BLUE POINTER株式会社成立',
            size2: '开设3家分公司',
            size3: '开设5家分公司'
        }
    },
    mupao: {
        txt: '沐泡广告联盟专注于互联网广告及网络营销领域的互联网营销公司。',
        jump: '前往 MUPAO>>'
    }
}
