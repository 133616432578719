import Vue from 'vue'
import App from './App.vue'
import './assets/reset.css'
import './assets/app.css'
import './assets/animate.min.css'
import 'swiper/css/swiper.css'
import router from './router'

import VueI18n from 'vue-i18n'
import axios from 'axios'
// axios.defaults.baseURL = 'http://124.156.225.201:8001/'
Vue.prototype.$http = axios
Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: 'jp',
    messages: {
        'zh': require('./locales/lan-zh'),
        'jp': require('./locales/lan-jp'),
        'en': require('./locales/lan-en')
    }
})

Vue.config.productionTip = false

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
