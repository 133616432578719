<template>
  <div>
    <div class="home-layer page2">
      <header-view/>
      <div class="dcloud-bg"></div>
      <div class="dcloud-content" v-show="isCurrentPage">
        <h2 class="fadeInUp animated delay1">ORENJI</h2>
        <p class="fadeInUp animated delay2 hr"></p>
        <p v-show="lang !== 'en'" class="fadeInUp animated delay3 txt">
          {{ $t('lang.home.dcloudpage') }}
        </p>
        <p class="fadeInUp animated delay4 txt base-size">
          ORENJI always adheres to the needs of users as the starting point, combined with leading data acceleration
          technology, to create products and solutions that truly meet the needs of users. In terms of static resource
          acceleration, ORENJI integrated CDN has successfully provided integrated CDN services for nearly 500000
          websites through cooperation with domestic well-known front-end open-source station bootcdn and well-known UI
          component library iView.
        </p>
        <a class="learnmore fadeInDown animated delay5">{{ $t('lang.home.learnmore') }}</a>
      </div>
      <img
          v-show="isCurrentPage"
          @click="jump(2)"
          class="arrowdown fadeInDown animated delay6"
          src="../../public/images/arrow-down.png">
    </div>
  </div>
</template>

<script>
import headerView from "@/components/layout/header";

export default {
  name: "dcloudPage",
  props: {
    swiper: Object
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    isCurrentPage() {
      let idx = 0;
      if (this.swiper && this.swiper.hasOwnProperty('realIndex')) {
        idx = this.swiper.realIndex
      }
      return idx === 2
    }
  },
  methods: {
    jump(idx) {
      this.swiper.slideTo(idx, 300, false)
    }
  },
  components: {
    headerView
  }
}
</script>

<style lang="less" scoped>

.dcloud-content {
  position: absolute;
  width: 640px;
  right: 15%;
  top: 50%;
  font-weight: normal;
  text-align: left;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);

  > h2 {
    font-size: 40px;
  }
}


.dcloud-bg {
  height: 100%;
  width: 100%;
  position: absolute;
  background-image: url('../../public/images/dcloudpage.jpg');
  background-size: cover;
}

.durationDelay(@duration, @delay) {
  animation-duration: @duration;
  -webkit-animation-duration: @duration;
  animation-delay: @delay;
  -webkit-animation-delay: @delay;
}

.swiper-slide-active {
  .delay1 {
    .durationDelay(1s, 0.5s)
  }

  .delay2 {
    .durationDelay(1s, 1s)
  }

  .delay3 {
    .durationDelay(1s, 1.5s)
  }

  .delay4 {
    .durationDelay(1s, 2s)
  }

  .delay5 {
    .durationDelay(1s, 2.5s)
  }

  .delay6 {
    .durationDelay(2s, 3s)
  }
}

</style>

