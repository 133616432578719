<template>
  <div class="international">
    <div class="international_bgc"></div>
    <header-view />
    <div class="international_conter" v-show="isCurrentPage">
      <div class="item">
        <div
          class="business_text fadeIn animated delay1"
          v-show="lang !== 'en'"
        >
          {{ $t("lang.home.internationaltitle") }}
        </div>
        <div class="business_eng fadeIn animated delay2">
          INTERNATIONAL BUSINESS
        </div>
        <div class="business_line fadeIn animated delay3"></div>
        <div
          v-show="lang !== 'en'"
          class="fadeInUp animated delay4 business_chinese"
        >
          {{ $t("lang.home.internationalpage.frist") }}
        </div>
        <div
          v-show="lang !== 'en'"
          class="fadeInUp animated delay4 business_chinese"
        >
          {{ $t("lang.home.internationalpage.two") }}
        </div>
        <!-- <div class="business_chinese fadeIn animated delay4">
                 <p>For North America, Western Europe and many other global top 500 brands</p>
                 <p>And "outbound" Chinese brands to provide international communication services.</p>
                 </div> -->
        <div class="business_English fadeIn animated delay5">
          <p>
            For North America, Western Europe and many other global top 500
            brands
          </p>
          <p>
            And "going out to sea" Chinese brands to provide international
            communication
          </p>
          <p>services.</p>
        </div>
        <div class="learnmore fadeIn animated delay6">
          {{ $t("lang.home.learnmore") }}
        </div>
      </div>
    </div>
    <div>
      <img
        v-show="isCurrentPage"
        @click="jump(2)"
        class="arrowdown fadeInDown animated delay7"
        src="../../public/images/arrow-down.png"
      />
    </div>
  </div>
</template>
<script>
import headerView from "@/components/layout/header";
export default {
  name: "internationaPage",
  props: {
    swiper: Object,
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
    isCurrentPage() {
      let idx = 0;
      if (this.swiper && this.swiper.hasOwnProperty("realIndex")) {
        idx = this.swiper.realIndex;
      }
      return idx === 3;
    },
  },
  methods: {
    jump(idx) {
      this.swiper.slideTo(idx, 300, false);
    },
  },
  components: {
    headerView,
  },
};
</script>
<style lang="less" scoped>
.international_bgc {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: url("../../public/images/internation-1.jpg") !important;
  background-size: cover;
}
.international_conter {
  position: absolute;
  width: 640px;
  right: 14%;
  top: 50%;
  transform: translate(0px, -60%);
  .item {
    width: 640px;
    height: 100%;
    text-align: left;
    .business_text {
      font-size: 40px;
      text-align: left;
    }
    .business_eng {
      margin-top: 15px;
      width: 193px;
      height: 68px;
      font-size: 24px;
      font-weight: normal;
    }
    .business_chinese {
      width: 640px;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      margin: 10px 0px;
    }
    .business_line {
      width: 640px;
      height: 1px;
      background-color: #fff;
      margin: 20px 0px;
    }
    .business_English {
      width: 640px;
      margin: 20rpx 0px;
      font-weight: normal;
    }
    .more {
      width: 84px;
      height: 30px;
      border: 1px solid #fff;
      border-radius: 17px;
      // padding: 0px 10px;
      line-height: 30px;
      text-align: center;
      margin: 20px 0px;
    }
  }
}
.durationDelay(@duration, @delay) {
  animation-duration: @duration;
  -webkit-animation-duration: @duration;
  animation-delay: @delay;
  -webkit-animation-delay: @delay;
}

.swiper-slide-active {
  .delay1 {
    .durationDelay(1s, 0.5s);
  }

  .delay2 {
    .durationDelay(1s, 1s);
  }

  .delay3 {
    .durationDelay(1s, 1.5s);
  }

  .delay4 {
    .durationDelay(1s, 2s);
  }

  .delay5 {
    .durationDelay(1s, 2.5s);
  }

  .delay6 {
    @include durationDelay(2s, 3s);
  }
  .delay7 {
    @include durationDelay(2s, 3.5s);
  }
}
</style>
