<template>
  <swiper ref="mySwiper" class="swiper" :options="swiperOption">
    <swiper-slide>
      <home-page :swiper="swiper"/>
    </swiper-slide>
    <swiper-slide>
      <service-page :swiper="swiper"/>
    </swiper-slide>
    <swiper-slide>
      <dcloud-page :swiper="swiper"/>
    </swiper-slide>
    <swiper-slide>
      <international-page :swiper="swiper"/>
    </swiper-slide>
    <swiper-slide>
      <mupao-page :swiper="swiper"/>
    </swiper-slide>
    <swiper-slide>
      <introduce-page :swiper="swiper"/>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import homePage from "@/components/homepage";
import dcloudPage from "@/components/dcloudPage";
import mupaoPage from "@/components/mupaoPage";
import introducePage from "@/components/introducePage";
import servicePage from "@/components/servicePage";
import internationalPage from "@/components/internationalPage"

export default {
  name: 'Home',
  data() {
    return {
      swiper: null,
      swiperOption: {
        slidesPerView: 1,
        // spaceBetween: 0,
        mousewheel: true,
        direction: 'vertical',
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      }
    }
  },
  mounted() {
    this.swiper = this.$refs['mySwiper'].$swiper
  },
  components: {
    Swiper,
    SwiperSlide,
    homePage,
    dcloudPage,
    mupaoPage,
    introducePage,
    servicePage,
    internationalPage
  }
}
</script>

<style lang="less" scoped>
.swiper {
  width: 100vw;
  height: 100vh;
  min-width: 1447px;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
  }
}
</style>
